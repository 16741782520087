import styled from "styled-components"

export const PageGrid = styled.div`
  display: grid;
  gap: 1rem;
  --rows: 1;
  grid-template-rows: repeat(var(--rows), minmax(auto, 1fr));

  hr {
    line-height: 1rem;
  }
  @media (max-width: 1100px) {
    margin: 1rem;
  }
`

export const ListsStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* grid-template-rows: repeat(4, auto); */
  gap: 1rem;
  margin-bottom: 4rem;
  margin-top: 0;
  .mark {
    background: var(--yellow);
  }
  a {
    color: var(--green);
  }
  p {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  h1 {
    margin-bottom: 2rem;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    grid-template-columns: repeat(4, auto);
    grid-gap: 1rem 1rem;
    align-items: center;
    /* padding: 5px; */
    margin-top: 0;
    /* background: var(--red); */
    border-radius: 2px;
    text-decoration: none;
    font-size: clamp(1.5rem, 1.4vw, 2.5rem);
    .count {
      background: white;
      padding: 2px 5px;
    }
    font-size: var(--heading-1);
    &[aria-current="page"] {
      background: var(--yellow);
    }
  }
  @media (max-width: 900px) {
    fieldset.menu,
    fieldset.order {
      grid-column: span 2;
    }
  }
`
