import React from "react"
import PodcastMic from "../assets/images/podcast-mic.svg"
import PodcastPlayer from "../assets/images/podcast-player.svg"
import { ListsStyle, PageGrid } from "../styles/Grids"

export default function PodcastingPage({
  data: {
    allMarkdownRemark: { nodes },
  },
}) {
  const podcastingData = nodes[0].frontmatter
  return (
    <PageGrid>
      <h1 className="center">{podcastingData.title}</h1>
      <div className="centerDiv">
        <img src={PodcastMic} alt="podcasting microphone" />
        <p>
          I co-host the &nbsp;
          <a
            href="https://podnutz.com/category/android-app-addicts/"
            target="_blank"
            rel="noreferrer"
          >
            Android App Addicts
          </a>
          &nbsp; podcast on Thursday nights. Time and weather permitting.
        </p>
      </div>
      <div className="centerDiv">
        <img src={PodcastPlayer} alt="podcast player" />

        <p>
          My favorite Podcatcher:{" "}
          <a
            href="https://www.podcastaddict.com/app"
            target="_blank"
            rel="noreferrer"
          >
            Podcast Addict
          </a>
          . It has tons of great features and customizations. Currently I am
          listening to most podcasts at about 3x speed.
        </p>
      </div>
      <ListsStyle>
        <h2 className="center">Some Podcasts I Listen To:</h2>
        <ul>
          {podcastingData.podcasts.map((podcast, i) => (
            <span key={podcast + i} className="mark center">
              {`<${podcast} />`}
            </span>
          ))}
        </ul>
      </ListsStyle>
    </PageGrid>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "podcasting" } } }
    ) {
      nodes {
        frontmatter {
          title
          intro
          podcasts
          templateKey
          featuredImage {
            id
            relativePath
            publicURL
          }
        }
      }
    }
  }
`
